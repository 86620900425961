/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= stylesheet_pack_tag 'stylesheets' %> to the appropriate
// layout file, like app/views/layouts/application.html.slim

$web-font-path: '//';
@import 'bootswatch/dist/yeti/variables';
@import 'bootstrap/scss/bootstrap';
@import 'bootswatch/dist/yeti/bootswatch';

// Import the Ace editor styles
@import url('ace-builds/css/ace.css');
@import url('ace-builds/css/theme/tomorrow.css');
@import url('ace-builds/css/theme/tomorrow_night.css');


// We define our own button style here, since `btn-outline-dark` and `btn-outline-light` do not switch colors.
html[data-bs-theme="dark"] {
  .btn-outline-contrast {
    @extend .btn-outline-light;
  }

  .bg-contrast {
    @extend .bg-light;
  }
}


html[data-bs-theme="light"] {
  .btn-outline-contrast {
    @extend .btn-outline-dark;
  }

  .bg-contrast {
    @extend .bg-dark;
  }
}

$fa-font-path: '@fortawesome/fontawesome-free/webfonts/';
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/v4-shims';
$opensans-path: 'opensans-webkit/fonts/';

// Import the Open Sans font. We need to override the font-display property
// to swap, otherwise the font will block rendering. This is done manually.
// as no variable is included yet.
// We inline the `opensans-webkit/src/sass/open-sans.scss`
@import 'opensans-webkit/src/sass/variables';

@mixin opensans-font($path, $name, $style, $weight, $variant) {

  @font-face {
    font-display: swap; // Manually added
    font-family: '#{$name}';
    font-style: $style;
    font-weight: $weight;
    src: local('Open Sans #{$variant}'),
    local('Open-Sans-#{$variant}'),
    url('#{$path}OpenSans-#{$variant}.woff2') format('woff2'),
    url('#{$path}OpenSans-#{$variant}.woff') format('woff');
  }
}

@include opensans-font($opensans-path, $opensans-name, normal, $opensans-weight-extrabold, 'ExtraBold');
@include opensans-font($opensans-path, $opensans-name, italic, $opensans-weight-extrabold, 'ExtraBoldItalic');

@include opensans-font($opensans-path, $opensans-name, normal, $opensans-weight-bold, 'Bold');
@include opensans-font($opensans-path, $opensans-name, italic, $opensans-weight-bold, 'BoldItalic');

@include opensans-font($opensans-path, $opensans-name, normal, $opensans-weight-semibold, 'SemiBold');
@include opensans-font($opensans-path, $opensans-name, italic, $opensans-weight-semibold, 'SemiBoldItalic');

@include opensans-font($opensans-path, $opensans-name, normal, $opensans-weight-normal, 'Regular');
@include opensans-font($opensans-path, $opensans-name, italic, $opensans-weight-normal, 'Italic');

@include opensans-font($opensans-path, $opensans-name, normal, $opensans-weight-light, 'Light');
@include opensans-font($opensans-path, $opensans-name, italic, $opensans-weight-light, 'LightItalic');
